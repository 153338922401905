import axios from './axiosInit'

const createUser = async data =>
{
    // console.log('coming here', data)
    const resp = await axios.post('users/create', data)
    return resp
}

const updateProfile = async data =>
{
    // console.log('coming here', data)
    const resp = await axios.post('update-profile', data)
    return resp.data
}


const getList = async (data,options) =>
{
    try
    {
        const result = await axios.get('users/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
        +'&role=' + data.role)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getOwnerList = async (data,options) =>
{
    try
    {
        const result = await axios.get('users/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
        +'&role=' + data.role)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getOwnerManagerList = async (data) =>
{
    try
    {
        const result = await axios.get('users/list?role=' + data.role)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const userView = async id =>
{
    try
    {
        const result = await axios.get('userView/' + id)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getUser = async id =>
{
    try
    {
        const result = await axios.get('users/list?id=' + id)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const viewUser = async() =>
{
    try
    {
        const result = await axios.get('view-profile')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const updateUser = async body =>
{
    try
    {
        const result = await axios.post('users/update', body)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const updateStatus = async (url, body) =>
{
    try
    {
        const result = await axios.post(url, body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const resetPassword = async (body, url) =>
{
    try
    {
        const result = await axios.post(url,body)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const validateOtp = async (body, url) =>
{
    try
    {
        const result = await axios.post(url,body)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const newPassword = async (body) =>
{
    try
    {
        const result = await axios.post('forgot-password',body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


export default {
    createUser,
    getList,
    getOwnerManagerList,
    getOwnerList,
    updateUser,
    getUser,
    updateStatus,
    userView,
    resetPassword,
    validateOtp,
    newPassword,
    viewUser,
    updateProfile
}
