import axios from 'axios';
import config from '../../config';

require('dotenv').config();

// Axios init
axios.defaults.baseURL = config.MYBOAT_API_URL;

axios.interceptors.request.use(config =>
{
    let token = localStorage.getItem('authorization');
    // console.log(token);
    // if (!!store.get('accounts/token')) config.headers.common.authorization = 'Bearer ' + store.get('accounts/token');
    if (!!token) config.headers = { authorization: token }
    // config.headers = { authentication: process.env.VUE_APP_API_TOKEN }
    return config;
});
axios.interceptors.response.use(
    response =>
    {
        return response;
    },
    error =>
    {
        if ('401' == error.response.status) 
        {
            console.log('Unauthorized. Invalid credentials were used for access', error);
            localStorage.clear();
            window.location.reload();
        }
        else
        {
            console.log('Other Error', error);
        }

        return Promise.resolve({ error });
    }
);

export default axios;
